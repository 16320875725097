define(['app'], function (app) {

  const productSizeGuide = () => {
    const component = {};

    const _config = {
      selectors: {
        modalContent: '[data-modal-content]',
      },
    };

    const _init = (element) => {
      component.element = element;
      component.modalContent = component.element.querySelector(component.config.selectors.modalContent);
      component.addTabIndexesToTables(component.modalContent);
      return component;
    };

    const _addTabIndexesToTables = (modalContent) => {
      const availableTables = modalContent.getElementsByTagName("tbody");
      if(availableTables !== null) {
        for(let table of availableTables) {
          table.tabIndex = 0
        }
      }
    };

    component.config = _config;
    component.init = _init;
    component.addTabIndexesToTables = _addTabIndexesToTables;

    return component;
  };

  return productSizeGuide;
});

